import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import 'rxjs/add/operator/do';
import { Observable, throwError } from 'rxjs';
import { NbAuthService, NbAuthToken, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AppService } from './app.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: NbAuthService,
			private authSvc: AuthService,
			@Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.filter(req)) {
			return this.authService.isAuthenticatedOrRefresh()
				.pipe(
					switchMap(authenticated => {
						if (authenticated) {
								return this.authService.getToken().pipe(
									switchMap((token: NbAuthToken) => {
										const tokenRes = `${token.getValue()}`;
										const tokenSplit = tokenRes.split('|');
										const tokenJwt = 'Bearer ' + tokenSplit[tokenSplit.length - 1].trim();
										req = req.clone({
											setHeaders: {
												Authorization: tokenJwt,
											},
										});
										return next.handle(req);
									}),
								)
						} else {
							return next.handle(req);
						}
					}),
					catchError(err => {
						const error = err.error;
						let message = error.message || err.statusText;
						if ([401, 403].indexOf(err.status) !== -1) {
							this.authSvc.logout();
						} else if ([0, 500].indexOf(err.status) !== -1) {
							message = 'Something went wrong. Please try again or contact your administrator.'
						} else if (error.errors) {
							// display specific error from the api
							let msg = '';
							Object.keys(error.errors).map(key => msg += error.errors[key].join(''));
							message = msg;
						}
						return throwError(message);
					})
				)
		} else {
			return next.handle(req);
    }
  }
}