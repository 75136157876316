
const apiEnvMap = [
  {
    host: 'localhost',
    api: 'http://localhost:8888/api'
  },
  {
    host: 'develop.profiling.lanexcorp.com',
    api: 'https://develop.api.profiling.lanexcorp.com/api'
  },
  {
    host: 'profiling.lanexcorp.com',
    api: 'https://api.profiling.lanexcorp.com/api'
  }
];

function getApiEnv() {
  const currentHost = window.location.hostname;
  const api = apiEnvMap.filter((api) => api.host === currentHost);

  return api.length ? api[0].api : '';
}

export const domain = getApiEnv();

export const EMAIL_PATTERN = /@lanexcorp.com\s*$/;
export const ENTRY_PER_PAGE = 20;
export const MONTH_NAMES = ['(empty)', 'January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'];
export const AVAILABILITY_RATE = ['0%', '10%', '25%', '50%', '75%', '100%']
export const TABLE_LIMIT = [20,50,100]
export const COMPETENCY_RATING = { 3: 'entry', 4: 'competent', 5: 'expert' };