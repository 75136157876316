import { Observable } from 'rxjs';

export interface User {
  name: string;
  role: any;
  picture: string;
  profile: any;
  tools: any[];
  workExperience: WorkExperience[];
  education: Education[];
  awards: any[]
}

export interface Contacts {
  user: User;
  type: string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract getContacts(): Observable<Contacts[]>;
  abstract getRecentUsers(): Observable<RecentUsers[]>;
}

export interface Profile {
  position: string;
  about: string;
  image: string;
  id: any;
}

export enum Role {
  User = 'User',
  Admin = 'Admin',
  Manager = 'Manager'
}
export interface WorkExperience {
  achievements: string;
  responsibilities: string;
  technologies: string;
  from_date: Date;
  to_date: Date;
  company_name: string;
  job_title: string;
}
export interface Education {
  school: string;
  course: string;
  from_date: Date;
  to_date: Date;
}
