import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { domain } from 'app/app.constants';
import { BehaviorSubject } from 'rxjs';
import { Role } from 'app/@core/data/users';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  constructor(private http: HttpClient) {
  }

  login(values: { email: string, password: string }) {
    return this.http.post(domain + '/login', values, this.httpOptions);
  }

  logout() {
    return this.http.post(domain + '/logout', {}, this.httpOptions);
  }

  resetPassword(values: { email: string }) {
    return this.http.post(domain + '/forgot-password', values, this.httpOptions);
  }

  changePassword(data: any) {
    return this.http.post(domain + '/reset', data, this.httpOptions);
  }

  getRoles() {
    return this.http.get(`${domain}/role`, this.httpOptions);
  }

  setRoles(roles: any): void {
    localStorage.setItem('roles', JSON.stringify(roles));
  }

  getRolesValue(): any {
    return JSON.parse(localStorage.getItem('roles'));
  }

  isUserAccount(roleId: any): boolean {
    const roles = this.getRolesValue();
    return roles[roleId] ? roles[roleId] === Role.User : true;
  }

  getUserRole(roleId: any) {
    const roles = this.getRolesValue();
    return roles[roleId] ? roles[roleId] : Role.User;
  }

  getAdminRoleId() {
    const roles = this.getRolesValue();
    const res = Object.keys(roles).filter(k => roles[k] === Role.Admin).map(k => k);
    return res && res.length > 0 ? res[0] : 1
  }

  getUserRoleId() {
    const roles = this.getRolesValue();
    const res = Object.keys(roles).filter(k => roles[k] === Role.User).map(k => k);
    return res && res.length > 0 ? res[0] : 1
  }
}
