import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { NbAuthService } from "@nebular/auth";
import { UserService } from "app/@core/mock/users.service";
import { tap } from "rxjs/operators";
import { AppService } from "./app.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService,
              private authSvc: AuthService,
              private appSvc: AppService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          const user = JSON.parse(localStorage.getItem('user'));
          if (!authenticated) {
            this.router.navigate(['/auth/login']);
          }
          const roles = this.authSvc.getRolesValue();
          let roleIds = [];
          let existRoutes = [];
          if (roles) {
            const roleNames =  Object.keys(roles).map(k => roles[k]);
            roleIds =  Object.keys(roles).map(k => Number(k));
            existRoutes = (route.data.roles || []).filter(element => roleNames.includes(element));
          }
          // check if route is restricted by role
          if (route.data.roles && existRoutes.length > 0 && roleIds.indexOf(user?.role_id) === -1) {
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
        }
        }),
      );
  }
}