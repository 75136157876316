import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NbToastrService, NbComponentStatus, NbIconConfig } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class AppService {

	constructor(private toastrService: NbToastrService) {}

	private screenSize = new BehaviorSubject(-1); // this will be used in checking mobile screens

	setScreenSize(size: number): void {
		this.screenSize.next(size);
	}

	getScreenSizeObv(): Observable<number> {
		return this.screenSize.asObservable();
	}

	isMobile(): boolean {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	}

	showMessage(status: NbComponentStatus, message: string, title: string, icn?:any, durationSec?: any) {
    	let icon: NbIconConfig = icn;
		const alertTitle = status === 'danger' ? 'Woops!' : title;
		if (!icn) {
			// set icon based on the status
			switch(status.toLowerCase()) {
				case 'primary':
				case 'success': icon = { icon: 'checkmark-outline', pack: 'eva' }; break;
				case 'warning':
				case 'danger':
				case 'info':
				case 'basic':
				case 'control': icon = { icon: 'alert-circle-outline', pack: 'eva' }; break;
			}
		}
		let duration = durationSec ? durationSec : 3000;
		this.toastrService.show(message, alertTitle, { status, icon, duration });
	}
}