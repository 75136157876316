import { of as observableOf,  Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, Role, User, UserData } from '../data/users';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { domain } from 'app/app.constants';
import { id } from 'date-fns/locale';

@Injectable()
export class UserService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  user = new BehaviorSubject(null);
  profileUser = new BehaviorSubject(null);
  userImage = new BehaviorSubject('');

  constructor(private http: HttpClient) { }

  private time: Date = new Date;

  private users = {
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png', profile: null, workExperience: [], education: [], tools: [], role: Role.User, awards: [] },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png', profile: null, workExperience: [], education: [], tools: [], role: Role.User, awards: [] },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png', profile: null, workExperience: [], education: [], tools: [], role: Role.User, awards: [] },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png', profile: null, workExperience: [], education: [], tools: [], role: Role.User, awards: [] },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png', profile: null, workExperience: [], education: [], tools: [], role: Role.User, awards: [] },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png', profile: null, workExperience: [], education: [], tools: [], role: Role.User, awards: [] },
  };
  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private contacts: Contacts[] = [
    { user: this.users.nick, type: this.types.mobile },
    { user: this.users.eva, type: this.types.home },
    { user: this.users.jack, type: this.types.mobile },
    { user: this.users.lee, type: this.types.mobile },
    { user: this.users.alan, type: this.types.home },
    { user: this.users.kate, type: this.types.work },
  ];
  private recentUsers: RecentUsers[]  = [
    { user: this.users.alan, type: this.types.home, time: this.time.setHours(21, 12)},
    { user: this.users.eva, type: this.types.home, time: this.time.setHours(17, 45)},
    { user: this.users.nick, type: this.types.mobile, time: this.time.setHours(5, 29)},
    { user: this.users.lee, type: this.types.mobile, time: this.time.setHours(11, 24)},
    { user: this.users.jack, type: this.types.mobile, time: this.time.setHours(10, 45)},
    { user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 42)},
    { user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 31)},
    { user: this.users.jack, type: this.types.mobile, time: this.time.setHours(8, 0)},
  ];

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }

  getUserDetails(): Observable<any> {
    return this.user.asObservable();
  }

  setUserDetails(user: any) {
    if (user.id === this.getUserLocalInfo().id) {
      this.user.next(user);
    } else {
      this.profileUser.next(user);
    }
  }

  resetUserDetails() {
    this.user.next(null);
  }

  updateUserInfo(value: any): Observable<any> {
    return this.http.put(`${domain}/user/${value?.id}`, value,  this.httpOptions);
  }

  getUserLocalInfo(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  getAllUser(pageIndex: number): Observable<any> {
    return this.http.get(`${domain}/user?page=${pageIndex}`,  this.httpOptions);
  }

  getUserById(userId: any): Observable<any> {
    return this.http.get(`${domain}/user/${userId}`,  this.httpOptions);
  }

  getUserByFilter(queryParams: any): Observable<any> {
    return this.http.get(`${domain}/user/?${queryParams}`, this.httpOptions);
  }

  getProfileUser(): Observable<any> {
    return this.profileUser.asObservable();
  }
}
